import { ReactComponent as HomeIcon } from "../../assets/svg/home.svg"
import { ReactComponent as Latest } from "../../assets/svg/latest.svg" 
import { ReactComponent as Settings } from "../../assets/svg/settings.svg" 
import { ReactComponent as Aboutus } from "../../assets/svg/aboutus.svg" 
import { ReactComponent as Gallery } from "../../assets/svg/gallery.svg" 
import { ReactComponent as Blog } from "../../assets/svg/blog.svg" 
import { ReactComponent as Event } from "../../assets/svg/events.svg" 

import { ReactComponent as Family } from "../../assets/svg/family.svg" 
import { ReactComponent as Children} from "../../assets/svg/children.svg" 
import { ReactComponent as Council} from "../../assets/svg/council.svg" 
import { ReactComponent as BloodDonation} from "../../assets/svg/blood.svg" 
import { ReactComponent as Homeless} from "../../assets/svg/homeless.svg" 

import { ReactComponent as Members } from "../../assets/svg/members.svg" 
import { ReactComponent as Contact} from "../../assets/svg/contact.svg" 

const data = [
  {
    name:"Home",
    link:"/",
    icon:<HomeIcon className = "icon"/>
  },
  {
    name:"Latest",
    icon:<Latest className = "icon" />,
    link:"",
    data:[
            {
        name:"Gallery",
        icon:<Gallery className ="icon" />,
        link:"/gallery"
      },
      {
        name:"Blog",
        icon:<Blog className ="icon"/>,
        link:"/blog"
      },
       {
        name:"Events",
        icon:<Event className ="icon"/>,
        link:"/events"
      }
      ]
  },
  {
    name:"Programs",
    icon:<Settings className = "icon"/>,
    link:"",
    data:[
            {
        name:"Our Programs",
        icon:<Settings className ="icon" />,
        link:"/programs"
      },
      {
        name:"Family Empowering",
        icon:<Family className ="icon"/>,
        link:"/programs/family-empowerment"
      },
       {
        name:"Child Care",
        icon:<Children className ="icon"/>,
        link:"/programs/child-care"
      },
      {
        name:"Counselling",
        icon:<Council className ="icon"/>,
        link:"/programs/counselling"
      },
      {
        name:"Blood Donation",
        icon:<BloodDonation className ="icon"/>,
        link:"/programs/blood-donation"
      },
      {
        name:"Support Homeless",
        icon:<Homeless className ="icon"/>,
        link:"/programs/support-homeless"
      }
      ]
  },
   {
    name:"About",
    icon:<Aboutus className ="icon"/>,
    link:"",
    data:[
            {
        name:"About us",
        icon:<Aboutus className ="icon" />,
        link:"/aboutus"
      },
      {
        name:"Members",
        icon:<Members className ="icon"/>,
        link:"/aboutus/members"
      },
       {
        name:"Contact Us",
        icon:<Contact className ="icon"/>,
        link:"/aboutus/contact"
      }
      ]
  }
  ]
  export default data;