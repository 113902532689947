import React from "react"
import SEO from "../SEO/SEO"
import "./donate.css"
import data from "../../data"
import cbe from "../../assets/img/donate/cbe.jpg"
import telebirr from "../../assets/img/donate/telebirr.jpeg"
import abyssinya from "../../assets/img/donate/abyssinia.png"
import awash from "../../assets/img/donate/awash.jpeg"
const bankData = [
  {
    title:"Commercial Bank of Ethiopia",
    img:cbe,
    no:data.cbe
  },
  {
    title:"Telebirr",
    img:telebirr,
    no:data.telebirr
  },
  {
    title:"Abyssinya ",
    img:abyssinya,
    no:data.abyssinia
  },
  {
    title:"Awash",
    img:awash,
    no:data.awash
  }
  ]
function Donate() {
  return(
    <div className = "donate-wrapper">
    <SEO 
    title = "DONATE"
    keywords = "Holisti Donation,KFGC Holistic support us,kolfe mulu wengel Holistic support Info,Supporting kolfe mulu wengel,Donation Holistic Services team ,Holistic Services team support, Holistic kolfe full gospel donate ,Holistic service kolfe full gospel donate now,support now,kfgc Holistic donate now"
    description = "Kolfe mulu wengel (KFGC) Holistic team Support the ones in need.
      Donate Now!"
    />
      <div className ="donate">
        <div className = "donate-banner">
          
        </div>
         <div className = "donate-content-wrapper ">
            <div className = "donate-header">
               <h2 className = "donate-header-title lato">
               Empower. Transform. Create. Support Our Holistic Mission.
               </h2>
               <div className = "ubuntu donate-header-paragraph">
          Unleashing holistic potential for a better world, together.
               </div>
            </div>
            <div className = "donate-content">
              {bankData.map(_ => <Card item = {_}/>)}
            </div>
         </div>
      </div>
    </div>
    )
}

export default Donate;


const Card = ({item:{title,img,no}}) => {
  return (
      <div className = "donate-card-container">
       <img className = "donate-card-image" src ={img} alt = {title} loading = "lazy"/>
         <div className = "donate-card-content">
            <div className = "lato donate-card-title">
               {title}
            </div>
             <div className = "donate-card-number">
             ACC No : {no}  
            </div>
         </div>
      </div>
    )
}