const  data = {
  email:"kfgchurchholistic@gmail.com",
  phone:"0900000000",
    cbe:"1000000055555554",
    awash:"10000000555666",
    abyssinia:"100000005555666",
    telebirr:"1000000055556666",
   links:{
    facebook:"https://www.facebook.com",
         twitter:"https://www.twitter.com",
         linkedin:"https://www.linkedin.com",
         telegram:"https://www.telegram.com",
         instagram:"https://www.instagram.com"
       },
   pos:"7171",
   iframe:"https://www.youtube.com/embed/sVPYIRF9RCQ?si=jghU9L9VJSavy_zU?autoplay=1",
   "development":"http://localhost:8000",
   "production":"http://localhost:8000"
} 
export default data