import axio from "axios";
import handleToken from "../admin/handleToken";
import data from "../../data";

let baseURL;
if (process.env.NODE_ENV === "development") {
  baseURL = data.development;
} else {
  baseURL = data.production;
  //true
}

const axiosPublic = axio.create({
  // baseURL,
});

export default axiosPublic;

let token = handleToken();
console.log(token, "here is token");
export const axiosProtected = axio.create({
  // baseURL,
  headers: {
    authorization: `Bearer ${token}`,
  },
});
